.float-label {
  position: relative;
  border-radius: 5px;
}

.label {
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
}

.label-float {
  top: -11px;
  font-size: 10px;
  background-color: #ffffff;
  padding: 0 5px;
  border-radius: 4px;
}

/* Force update ant style */
.ant-input,
.ant-select .ant-select-selector,
.ant-input-affix-wrapper {
  padding: 11.9px;
  border-radius: 6px;
}

.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-radius: 6px;
    height: 48px;
  }
}
.ant-input-number-input-wrap,
.ant-input-number-input {
  height: 100%;
}
.ant-input-number,
.ant-picker {
  border-radius: 6px;
  height: 48px;
  width: 100%;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 0;
}

.ant-select-selection-item {
  top: 8px;
}
