.our-tutor {
  padding: 64px 20px;

  .tutor {
    .card {
      padding: 20px 10px;
      border-radius: 5px;

      transition: all 0.3s linear;

      &:hover {
        cursor: pointer;
        background-color: #f8f9fa;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
          rgba(0, 0, 0, 0.05) 0px 5px 10px;
        transition: all 0.3s linear;

        img {
          transform: scale(1.2);
          transition: all 0.3s linear;
        }
      }

      img {
        transition: all 0.3s linear;
      }
    }
  }

  .view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px #14398a;
    color: #14398a;
    margin: 20px auto;
    padding: 12px 12px;
    width: 250px;
    border-radius: 20px;

    &:hover {
      color: #ffffff;
      background-color: #14398a;
    }

    @media (max-width: 768px) {
      border: solid 1px #14398a;
    }
  }
}
