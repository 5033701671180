.login-layout {
  display: flex;
  margin: 0 auto;
  padding: 90px 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .card {
    .item {
      margin: auto;
      display: flex;
      padding: 16px;
      max-width: 412px;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
      border-radius: 5px;

      a:hover {
        border: solid 1px #fb923c;
        box-shadow: inset -10em 0 0 0 #fb923c, inset 10em 0 0 0 #fb923c;
        color: #fff;
      }
    }
  }
}

@media (max-width: 768px) {
  .card {
    flex-direction: column;
  }
}
