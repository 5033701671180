.search-section {
  .search-header {
    .signup-button {
      a {
        &:hover {
          box-shadow: inset -10em 0 0 0 #f97316, inset 10em 0 0 0 #f97316;
          color: #fff;
        }
      }
    }
  }
  .search-component {
    .filter-radio-mobile {
      &-item {
        display: flex;
        .option {
          border-radius: 5px 0 0 5px;
          padding: 0.7rem;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: width 0.25s;
          border: 1px solid #ced4da;
          background-color: #ffffff;

          &:nth-child(2) {
            border-radius: 0 5px 5px 0;
          }

          &.ant-checkbox-wrapper {
            margin: 0;
          }

          &.ant-checkbox-wrapper-checked {
            background-color: #14398a;
            margin: 0;

            path,
            span {
              color: #fff;
            }
          }

          .ant-checkbox {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .cards {
    .ant-list-items {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 10px;
    }
  }
}
