.tutoring-service {
  padding: 100px 3% 5%;
  font-size: 16px;

  @media (min-width: 768px) {
    padding: 100px 2% 2%;
  }

  &.container {
    text-align: left;
  }
  .title {
    font-size: 25px;
    text-align: center;
    margin-bottom: 12px;
    // max-width: 200px;
    // margin: 0 auto 20px;
    // padding: 10px 20px;
    // text-align: center;
    // border-radius: 8px;
    // color: #fff;
    // background-image: linear-gradient(180deg, #1488cc, #2b32b2);
  }

  .panel-body {
    .part {
      h3 {
        font-size: 20px;

        @media (min-width: 768px) {
          font-size: 25px;
        }
      }
    }
  }
}
