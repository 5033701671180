.loading_container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #00d2ff, #3a7bd5);

  // .lds-hourglass {
  //   display: inline-block;
  //   position: relative;
  //   width: 120px;
  //   height: 120px;

  //   &:after {
  //     content: " ";
  //     display: block;
  //     border-radius: 50%;
  //     width: 0;
  //     height: 0;
  //     margin: 8px;
  //     box-sizing: border-box;
  //     border: 51px solid #14398a;
  //     border-color: #14398a transparent #14398a transparent;
  //     animation: lds-hourglass 1.2s infinite;
  //   }
  // }

  .loader {
    width: 16em;
    height: 8em;
    position: relative;
    overflow: hidden;
  }

  .loader::before,
  .loader::after {
    content: "";
    position: absolute;
    bottom: 0;
  }

  .loader::before {
    width: inherit;
    height: 0.2em;
    background-color: hsla(0, 0%, 85%);
  }

  .loader::after {
    box-sizing: border-box;
    width: 50%;
    height: inherit;
    border: 0.2em solid hsla(0, 0%, 85%);
    border-radius: 50%;
    left: 25%;
  }

  .loader span {
    position: absolute;
    width: 5%;
    height: 10%;
    background-color: white;
    border-radius: 50%;
    bottom: 0.2em;
    left: -5%;
    animation: 2s linear infinite;
    transform-origin: 50% -3em;
    animation-name: run, rotating;
  }

  .loader span:nth-child(2) {
    animation-delay: 0.075s;
  }
  .loader span:nth-child(3) {
    animation-delay: 0.15s;
  }

  .status {
    animation: fadeIn 1s linear forwards;
    text-align: center;
    color: #fff;
    font-size: 20px;
    margin-top: 10px;

    .status__dot {
      color: #fff;
      animation: appear1 1s 1s steps(1, start) infinite;
      display: inline-block;
    }
    .status__dot:nth-child(2) {
      animation: appear2 1s 1s steps(1, start) infinite;
    }
    .status__dot:nth-child(3) {
      animation: appear3 1s 1s steps(1, start) infinite;
    }
  }
}

// @keyframes lds-hourglass {
//   0% {
//     transform: rotate(0);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//   }
//   50% {
//     transform: rotate(900deg);
//     animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
//   }
//   100% {
//     transform: rotate(1800deg);
//   }
// }

@keyframes run {
  0% {
    left: -5%;
  }
  10%,
  60% {
    left: calc((100% - 5%) / 2);
  }
  70%,
  100% {
    left: 100%;
  }
}

@keyframes rotating {
  0%,
  10% {
    transform: rotate(0deg);
  }
  60%,
  100% {
    transform: rotate(-1turn);
  }
}

@keyframes appear1 {
  from {
    visibility: hidden;
  }
  33%,
  to {
    visibility: visible;
  }
}
@keyframes appear2 {
  from,
  33% {
    visibility: hidden;
  }
  67%,
  to {
    visibility: visible;
  }
}
@keyframes appear3 {
  from,
  67% {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}
