.search-section {
  background: #f3f5f8;
  padding-top: 100px;

  .search-component {
    min-height: 1000px;
    padding-bottom: 6rem;

    .ant-select-selection-search {
      .ant-select-selection-search-input {
        height: 100%;
      }
    }

    .ant-select-selection-item {
      text-align: left;
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }

    .search-result {
      width: 100%;
      background-color: #ffffff;
      margin-bottom: 1.5rem;
      border-radius: 0.5rem;
      padding: 0.75rem;
      position: relative;
      box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.1);
      text-align: left;

      .image-holder {
        padding-right: 0.5rem;
        max-width: calc(7.813rem + 0.5rem);
        text-align: left;
        img {
          border-radius: 8px;
        }
      }

      .tutor-info {
        border-top: 1px solid #dee2e6;
        margin-top: 10px;
        padding-top: 10px;
        .item {
          font-size: 16px;
          svg {
            margin-right: 5px;
          }

          span {
            font-weight: 600;
          }
        }
      }

      .description {
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        margin: 10px 0 10px 0;
        padding: 10px 0 10px 0;
        font-size: 16px;
      }

      .contact {
        display: flex;
        justify-content: space-between;
        .verify {
          margin-top: 8px;
          margin-left: 8px;
        }
        button {
          font-size: 16px;
          padding: 10px 20px;
          color: #fff;
          background-color: #fb923c;
          border: none;

          &:hover {
            background-color: #e88b00;
          }
        }
      }
    }

    .header-total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      font-size: 16px;
      padding: 12px 0;
      border-top: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
    }

    .gender-container {
      .ant-checkbox-group {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .option {
          border-radius: 5px 0 0 5px;
          padding: 0.7rem;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: width 0.25s;
          border: 1px solid #ced4da;

          &.ant-checkbox-wrapper {
            margin: 0;
          }

          &.ant-checkbox-wrapper-checked {
            background-color: #14398a;
            margin: 0;

            path,
            span {
              color: #fff;
            }
          }

          .ant-checkbox {
            display: none;
          }
        }
        .second-option {
          border-radius: 0 5px 5px 0;
          margin: 0;
        }
      }
    }
  }

  .modal-filter-button {
    background: #fff;
    width: 3.125rem;
    height: 3.125rem;
    // position: fixed;
    // left: 1rem;
    // bottom: 1rem;
    // z-index: 1;
    border-radius: 8px;
    place-items: center;
    cursor: pointer;
    border: 1px solid #e9ecef;
    box-shadow: 0 2px 16px #bdc4ca;
  }

  .ant-modal-body {
    padding-bottom: 0;
  }

  .ant-modal-content {
    margin: 0.5rem;
    border-radius: 8px;
  }

  .ant-modal-footer {
    text-align: center;
    padding: 24px;
  }

  .swal2-input,
  .swal2-file,
  .swal2-textarea,
  .swal2-select,
  .swal2-radio,
  .swal2-checkbox {
    margin: 0;
  }
}

@media (min-width: 992px) {
  .search-section {
    .search-component {
      .sticky-filter {
        min-width: 22.5rem;
      }
    }
  }
}

@media (min-width: 768px) {
  .search-section {
    .search-component {
      .search-result {
        .image-holder {
          padding-right: 1rem;
          max-width: calc(10rem + 1rem);
        }
      }

      .header-total {
        border: none;
      }
    }
  }
}
