.contact-icon {
  display: block;
  position: fixed;
  right: 24px;
  bottom: 92px;
  z-index: 10;

  @media (max-width: 992px) {
    display: flex;
    right: 75px;
    bottom: 23px;
  }

  .pulse {
    display: grid;
    place-items: center;
    position: relative;
    top: 12px;

    @media (max-width: 992px) {
      top: 0;
    }

    .phone-icon {
      box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px 0px;
      border-radius: 50%;
      background-color: #14398a;

      path {
        color: #fff;
      }
    }

    .animate-icon {
      position: absolute;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      z-index: -1;
      background-color: #14398a;
    }
  }

  .zalo-icon {
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 12px 0px;
    border-radius: 50%;
  }
}
