/* color #14398a */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
}

h4 {
  color: #f3f5f8;
}

a:hover,
button:hover,
ul:hover,
li:hover {
  transition: all linear 0.3s;
}

a {
  font-weight: 600;
  color: #000000;
  transition: all linear 0.3s;
}

button,
.ant-btn {
  font-weight: 600;
  transition: all linear 0.3s;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #14398a;
  border-radius: 6px;
  padding: 5px 10px;
  color: #14398a;
}

ul {
  transition: all linear 0.3s;
  margin: 0;
}

li {
  transition: all linear 0.3s;
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: 600;
}

p {
  margin: 0;
}

img {
  margin: auto;
}

.container {
  margin: 0 auto;
  text-align: center;
}

.ant-back-top {
  @media (max-width: 768px) {
    bottom: 75px;
  }
  .ant-back-top-content {
    background-color: #2564ebb0;

    &:hover {
      background-color: #14398a;
    }

    path {
      color: #fff;
    }
  }
}

.ant-tooltip-inner,
.ant-popover-inner {
  border-radius: 6px;
}

.ant-popover-inner-content {
  padding: 0;
}

.ant-pagination-options {
  display: none;
}

.anticon {
  svg,
  path {
    color: inherit;
  }
}

.ant-form-item-children-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-menu-inline {
  .ant-menu-item,
  .ant-menu-submenu-title {
    width: 100%;
  }
}

.slick-arrow {
  border: black 1px solid;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  &:before {
    color: black;
    font-size: 50px;
    line-height: 0.35;
    transition: all linear 0.3s;
  }

  &:hover {
    border: #fb923c 1px solid;
    &:before {
      color: #fb923c;
      transition: all linear 0.3s;
    }
  }
}
.slick-arrow.slick-next {
  right: 0;
  &:before {
    content: "￫";
  }
}

.slick-arrow.slick-prev {
  left: 0;
  &:before {
    content: "￩";
  }
}
