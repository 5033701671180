.love-us {
  padding: 64px 20px;
  background: #f8f9fa;

  .center {
    padding: 0 50px;
    .card {
      opacity: 0.8;
      transition: all 0.3s ease;
      background: #fff8f2;
      padding: 4%;
      position: relative;
      text-align: start;

      .quatation-marks {
        position: absolute;
        top: 20%;
        right: 5%;
        width: 8%;
        z-index: -1;
      }
    }

    .slick-center {
      .card {
        opacity: 1;
        transform: scale(1.08);
      }
    }

    .slick-list {
      .slick-track {
        .slick-slide {
          padding: 0 30px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .love-us {
    .center {
      padding: 0 30px;
      .slick-list {
        .slick-track {
          .slick-slide {
            padding: 0 20px;
          }
        }
      }

      .slick-arrow {
        width: 30px;
        height: 30px;
        &:before {
          font-size: 30px;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .love-us {
    .center {
      .slick-list {
        .slick-track {
          .slick-slide {
            padding: 0 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .love-us {
    .center {
      .slick-list {
        .slick-track {
          .slick-slide {
            padding: 0;
          }
        }
      }
    }
  }
}
