.sticky-filter {
  .filters-container {
    position: relative;
    width: 100%;

    .filters {
      background: #fff;
      border-radius: 6px;

      .filter-item {
        margin-left: 0;
        margin-right: 0;

        .ant-select {
          width: 100%;

          &-selection-search {
            padding: 12px 0;

            input {
              height: auto;
            }
          }

          &-selection-item {
            text-align: left;
          }

          &-selection-placeholder {
            line-height: inherit;
            padding: 12px 0;
            text-align: left;
          }
        }

        &-title {
          text-align: left;
          margin-top: 1rem;
          display: block;
          font-weight: 500;
          font-size: 1rem;
        }

        .tutoring-radio-container {
          .ant-form-item-control-input-content {
            display: flex;

            .option {
              border-radius: 5px 0 0 5px;
              padding: 0.7rem;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: width 0.25s;
              border: 1px solid #ced4da;

              &:nth-child(2) {
                border-radius: 0 5px 5px 0;
              }

              &.ant-checkbox-wrapper {
                margin: 0;
              }

              &.ant-checkbox-wrapper-checked {
                background-color: #14398a;
                margin: 0;

                path,
                span {
                  color: #fff;
                }
              }

              .ant-checkbox {
                display: none;
              }
            }

            .option:hover {
              background-color: #14398a;
              border: 1px solid #14398a;

              path,
              span {
                color: #fff;
              }
            }
          }
        }

        .ant-input-affix-wrapper {
          z-index: 1;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        .ant-input-search-button {
          width: 48px;
          height: 48px;
          border: 1px solid #d9d9d9;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;

          &:hover {
            border: 1px solid #40a9ff;
          }
        }
      }

      .filter-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e9ecef;
      }
    }
  }
}

@media (min-width: 1024px) {
  .sticky-filter {
    .filters-container {
      width: 360px;
    }
  }
}
@media (min-width: 992px) {
  .sticky-filter {
    .filters-container {
      .filters {
        .filter-item {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
        }
      }
    }
  }
}
