.sign-in {
  background: #f9f9f9;
  display: flex;
  align-items: center;
  padding: 100px 5%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: auto;
  }
  @media (max-width: 400px) {
    padding-top: 90px;
    padding-bottom: 80px;
  }

  .sign-in-left {
    width: 50%;
    text-align: left;

    @media (max-width: 768px) {
      width: 100%;
    }

    .arrow {
      width: 5%;
      position: absolute;
      top: 12%;
      left: 0;

      @media (max-width: 768px) {
        top: 30%;
      }
    }

    .form {
      margin-right: 32px;
      @media (max-width: 768px) {
        margin: 0;
      }

      h1 {
        font-weight: 700;
        font-size: 50px;
        color: #43546f;
        margin-bottom: 20px;

        @media (max-width: 992px) {
          font-size: 30px;
        }
        @media (max-width: 768px) {
          font-size: 24px;
        }
      }

      .forgot-password {
        margin-bottom: 30px;
        a {
          color: #43546f;
        }
      }

      .submit-form {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 45px 5px 50px;
        background: linear-gradient(
          117.49deg,
          #384a65 -81.04%,
          #6e8ebd 135.17%
        );
        box-shadow: 8px 8px 60px rgba(60, 73, 92, 0.2);
        border-radius: 10px;

        @media (max-width: 992px) {
          padding: 5px 12px 5px 12px;
        }
        @media (max-width: 768px) {
          width: 100%;
        }

        .login {
          font-weight: 600;
          font-size: 25px;
          line-height: 37px;
          color: #ffffff;

          @media (max-width: 992px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
          }
        }
      }

      .register {
        position: relative;
        color: #43546f;

        img {
          margin: 0;
          height: 66px;
        }

        .text {
          position: absolute;
          top: 20px;
          left: 48px;

          @media (max-width: 320px) {
            left: 15px;
          }

          a {
            color: #43546f;
            margin-left: 5px;
          }
        }
      }

      .ant-form-item-with-help {
        margin-bottom: 30px;
      }
    }
  }
  .sign-in-right {
    width: 50%;
    height: 100%;

    .ilu {
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
}
