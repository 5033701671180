:root {
  --blue: #5e72e4;
  --indigo: #5603ad;
  --purple: #8965e0;
  --pink: #f3a4b5;
  --red: #f5365c;
  --orange: #fb6340;
  --yellow: #ffd600;
  --green: #2dce89;
  --teal: #11cdef;
  --cyan: #2bffc6;
  --white: #fff;
  --gray: #8898aa;
  --gray-dark: #32325d;
  --light: #ced4da;
  --lighter: #e9ecef;
  --primary: #5e72e4;
  --secondary: #f7fafc;
  --success: #2dce89;
  --info: #11cdef;
  --warning: #fb6340;
  --danger: #f5365c;
  --light: #adb5bd;
  --dark: #212529;
  --default: #172b4d;
  --white: #fff;
  --neutral: #fff;
  --darker: black;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

.user-profile {
  position: relative;

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  hr {
    overflow: visible;
    box-sizing: content-box;
    height: 0;
  }

  h1,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul ul {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: #5e72e4;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a:hover {
    text-decoration: none;
    color: #233dd2;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  button {
    border-radius: 0;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input,
  button,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  h1,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    color: #32325d;
  }

  h1,
  .h1 {
    font-size: 1.625rem;
  }

  h3,
  .h3 {
    font-size: 1.0625rem;
  }

  h4,
  .h4 {
    font-size: 0.9375rem;
  }

  h5,
  .h5 {
    font-size: 0.8125rem;
  }

  h6,
  .h6 {
    font-size: 0.625rem;
  }

  .display-2 {
    font-size: 2.75rem;
    font-weight: 600;
    line-height: 1.5;
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  .container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
  }

  .row {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
  }

  .col-4,
  .col-8,
  .col,
  .col-md-10,
  .col-md-12,
  .col-lg-3,
  .col-lg-4,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-xl-4,
  .col-xl-6,
  .col-xl-8 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col {
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-4 {
    max-width: 33.33333%;
    flex: 0 0 33.33333%;
  }

  .col-8 {
    max-width: 66.66667%;
    flex: 0 0 66.66667%;
  }

  @media (min-width: 768px) {
    .col-md-3 {
      max-width: 25%;
      flex: 0 0 25%;
    }

    .col-md-6 {
      max-width: 50%;
      flex: 0 0 50%;
    }

    .col-md-10 {
      max-width: 83.33333%;
      flex: 0 0 83.33333%;
    }

    .col-md-12 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  @media (min-width: 992px) {
    .col-lg-3 {
      max-width: 25%;
      flex: 0 0 25%;
    }

    .col-lg-4 {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
    }

    .col-lg-6 {
      max-width: 50%;
      flex: 0 0 50%;
    }

    .col-lg-7 {
      max-width: 58.33333%;
      flex: 0 0 58.33333%;
    }

    .col-lg-8 {
      max-width: 66.66667%;
      flex: 0 0 66.66667%;
    }

    .order-lg-2 {
      order: 2;
    }
  }

  @media (min-width: 1200px) {
    .col-xl-4 {
      max-width: 33.33333%;
      flex: 0 0 33.33333%;
    }

    .col-xl-6 {
      max-width: 50%;
      flex: 0 0 50%;
    }

    .col-xl-8 {
      max-width: 66.66667%;
      flex: 0 0 66.66667%;
    }

    .order-xl-1 {
      order: 1;
    }

    .order-xl-2 {
      order: 2;
    }
  }

  .form-control {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
    width: 100%;
    padding: 0.625rem 0.75rem;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #8898aa;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: none;
  }

  .form-control::-ms-expand {
    border: 0;
    background-color: transparent;
  }

  .form-control:focus {
    color: #8898aa;
    border-color: rgba(50, 151, 211, 0.25);
    outline: 0;
    background-color: #fff;
    box-shadow: none, none;
  }

  .form-control:-ms-input-placeholder {
    opacity: 1;
    color: #adb5bd;
  }

  .form-control::-ms-input-placeholder {
    opacity: 1;
    color: #adb5bd;
  }

  .form-control::placeholder {
    opacity: 1;
    color: #adb5bd;
  }

  .form-control:disabled,
  .form-control[readonly] {
    opacity: 1;
    background-color: #e9ecef;
  }

  textarea.form-control {
    height: auto;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .btn {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    display: inline-block;
    padding: 0.625rem 1.25rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: 0.375rem;
  }

  .btn:hover,
  .btn:focus {
    text-decoration: none;
  }

  .btn:focus {
    outline: 0;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  .btn:disabled {
    opacity: 0.65;
    box-shadow: none;
  }

  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .btn:not(:disabled):not(.disabled):active {
    box-shadow: none;
  }

  .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08),
      none;
  }

  .btn-primary {
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .btn-primary:hover {
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
  }

  .btn-primary:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
      0 0 0 0 rgba(94, 114, 228, 0.5);
  }

  .btn-primary:disabled {
    color: #fff;
    border-color: #5e72e4;
    background-color: #5e72e4;
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #5e72e4;
    background-color: #324cdd;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5);
  }

  .btn-info {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .btn-info:hover {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
  }

  .btn-info:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
      0 0 0 0 rgba(17, 205, 239, 0.5);
  }

  .btn-info:disabled {
    color: #fff;
    border-color: #11cdef;
    background-color: #11cdef;
  }

  .btn-info:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #11cdef;
    background-color: #0da5c0;
  }

  .btn-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(17, 205, 239, 0.5);
  }

  .btn-default {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .btn-default:hover {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
  }

  .btn-default:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08),
      0 0 0 0 rgba(23, 43, 77, 0.5);
  }

  .btn-default:disabled {
    color: #fff;
    border-color: #172b4d;
    background-color: #172b4d;
  }

  .btn-default:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #172b4d;
    background-color: #0b1526;
  }

  .btn-default:not(:disabled):not(.disabled):active:focus {
    box-shadow: none, 0 0 0 0 rgba(23, 43, 77, 0.5);
  }

  .btn-sm {
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 0.25rem 0.5rem;
    border-radius: 0.375rem;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    background-color: #fff;
    background-clip: border-box;
  }

  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }

  .card-body {
    padding: 1.5rem;
    flex: 1 1 auto;
  }

  .card-header {
    margin-bottom: 0;
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #fff;
  }

  .card-header:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
  }

  .bg-secondary {
    background-color: #f7fafc !important;
  }

  a.bg-secondary:hover,
  a.bg-secondary:focus,
  button.bg-secondary:hover,
  button.bg-secondary:focus {
    background-color: #d2e3ee !important;
  }

  .border-0 {
    border: 0 !important;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  .d-none {
    display: none !important;
  }

  .d-flex {
    display: flex !important;
  }

  @media (min-width: 768px) {
    .d-md-flex {
      display: flex !important;
    }
  }

  @media (min-width: 992px) {
    .d-lg-inline-block {
      display: inline-block !important;
    }

    .d-lg-block {
      display: block !important;
    }
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  @media (min-width: 1200px) {
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
  }

  .shadow,
  .card-profile-image img {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mr-2 {
    margin-right: 0.5rem !important;
  }

  .ml-2 {
    margin-left: 0.5rem !important;
  }

  .mr-3 {
    margin-right: 1rem !important;
  }

  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }

  .mr-4 {
    margin-right: 1.5rem !important;
  }

  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-5 {
    margin-bottom: 3rem !important;
  }

  .mt--7 {
    margin-top: -6rem !important;
  }

  .pt-0 {
    padding-top: 0 !important;
  }

  .pr-0 {
    padding-right: 0 !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .pt-5 {
    padding-top: 3rem !important;
  }

  .pt-6 {
    padding-top: 4.5rem !important;
  }

  .pt-7 {
    padding-top: 6rem !important;
  }

  .pt-8 {
    padding-top: 8rem !important;
  }

  .pb-8 {
    padding-bottom: 8rem !important;
  }

  .m-auto {
    margin: auto !important;
  }

  @media (min-width: 768px) {
    .mt-md-5 {
      margin-top: 3rem !important;
    }

    .pt-md-4 {
      padding-top: 1.5rem !important;
    }

    .pb-md-4 {
      padding-bottom: 1.5rem !important;
    }
  }

  @media (min-width: 992px) {
    .pl-lg-4 {
      padding-left: 1.5rem !important;
    }

    .pt-lg-8 {
      padding-top: 8rem !important;
    }

    .ml-lg-auto {
      margin-left: auto !important;
    }
  }

  @media (min-width: 1200px) {
    .mb-xl-0 {
      margin-bottom: 0 !important;
    }
  }

  .text-right {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

  .font-weight-light {
    font-weight: 300 !important;
  }

  .font-weight-bold {
    font-weight: 600 !important;
  }

  .text-white {
    color: #fff !important;
  }

  .text-white {
    color: #fff !important;
  }

  a.text-white:hover,
  a.text-white:focus {
    color: #e6e6e6 !important;
  }

  .text-muted {
    color: #8898aa !important;
  }

  @media print {
    *,
    *::before,
    *::after {
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a:not(.btn) {
      text-decoration: underline;
    }

    img {
      page-break-inside: avoid;
    }

    p,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h3 {
      page-break-after: avoid;
    }

    @page {
      size: a3;
    }

    body {
      min-width: 992px !important;
    }

    .container {
      min-width: 992px !important;
    }
  }

  .bg-gradient-default {
    background: linear-gradient(87deg, #6a7992 0, #5e5c84 100%);
  }

  @keyframes floating-lg {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(15px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  @keyframes floating {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  @keyframes floating-sm {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(5px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  .center {
    left: 50%;
    transform: translateX(-50%);
  }

  [class*="shadow"] {
    transition: all 0.15s ease;
  }

  .font-weight-300 {
    font-weight: 300 !important;
  }

  .text-sm {
    font-size: 0.875rem !important;
  }

  .text-white {
    color: #fff !important;
  }

  a.text-white:hover,
  a.text-white:focus {
    color: #e6e6e6 !important;
  }

  .avatar {
    font-size: 1rem;
    display: inline-flex;
    width: 48px;
    height: 48px;
    color: #fff;
    border-radius: 50%;
    background-color: #adb5bd;
    align-items: center;
    justify-content: center;
  }

  .avatar img {
    width: 100%;
    border-radius: 50%;
  }

  .avatar-sm {
    font-size: 0.875rem;
    width: 36px;
    height: 36px;
  }

  .btn {
    font-size: 0.875rem;
    position: relative;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    text-transform: none;
    will-change: transform;
  }

  .btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  .btn:not(:last-child) {
    margin-right: 0.5rem;
  }

  .btn i:not(:first-child) {
    margin-left: 0.5rem;
  }

  .btn i:not(:last-child) {
    margin-right: 0.5rem;
  }

  .btn-sm {
    font-size: 0.75rem;
  }

  [class*="btn-outline-"] {
    border-width: 1px;
  }

  .card-profile-image {
    position: relative;
  }

  .card-profile-image img {
    position: absolute;
    left: 50%;
    max-width: 180px;
    transition: all 0.15s ease;
    transform: translate(-50%, -30%);
    border-radius: 0.375rem;
  }

  .card-profile-image img:hover {
    transform: translate(-50%, -33%);
  }

  .card-profile-stats {
    padding: 1rem 0;
  }

  .card-profile-stats > div {
    margin-right: 1rem;
    padding: 0.875rem;
    text-align: center;
  }

  .card-profile-stats > div:last-child {
    margin-right: 0;
  }

  .card-profile-stats > div .heading {
    font-size: 1.1rem;
    font-weight: bold;
    display: block;
  }

  .card-profile-stats > div .description {
    font-size: 0.875rem;
    color: #adb5bd;
  }

  .navbar-top {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    padding-right: 0 !important;
    padding-left: 0 !important;
    background-color: transparent;
  }

  @media (min-width: 768px) {
    .container-fluid {
      padding-right: 39px !important;
      padding-left: 39px !important;
    }
  }

  .dropdown {
    display: inline-block;
  }

  .form-control-label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #525f7f;
  }

  .form-control {
    font-size: 0.875rem;
  }

  p.form-control {
    margin-bottom: 0.5rem;
    line-height: 2;
  }

  .form-control:focus:-ms-input-placeholder {
    color: #adb5bd;
  }

  .form-control:focus::-ms-input-placeholder {
    color: #adb5bd;
  }

  .form-control:focus::placeholder {
    color: #adb5bd;
  }

  textarea[resize="none"] {
    resize: none !important;
  }

  textarea[resize="both"] {
    resize: both !important;
  }

  textarea[resize="vertical"] {
    resize: vertical !important;
  }

  textarea[resize="horizontal"] {
    resize: horizontal !important;
  }

  .form-control-alternative {
    transition: box-shadow 0.15s ease;
    border: 0;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  }

  .form-control-alternative:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .focused .form-control {
    border-color: rgba(50, 151, 211, 0.25);
  }

  .bg-img {
    position: relative;
  }

  .focused .form-control {
    border-color: rgba(50, 151, 211, 0.25);
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.15s ease;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    .mask {
      transition: none;
    }
  }

  @keyframes show-navbar-collapse {
    0% {
      transform: scale(0.95);
      transform-origin: 100% 0;
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes hide-navbar-collapse {
    from {
      transform: scale(1);
      transform-origin: 100% 0;
      opacity: 1;
    }

    to {
      transform: scale(0.95);
      opacity: 0;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
  }

  .description {
    font-size: 0.875rem;
  }

  .heading {
    font-size: 0.95rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-transform: uppercase;
  }

  .heading-small {
    font-size: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  .display-2 span {
    font-weight: 300;
    display: block;
  }

  @media (max-width: 768px) {
    .btn {
      margin-bottom: 10px;
    }
  }
}

.user-profile {
  .delete-button {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #eb4032;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
      top: -15px;
      right: -15px;
    }
  }
}
