.contact-page {
  padding: 120px 3% 10%;

  .content {
    display: flex;
    @media (max-width: 991px) {
      display: block;
    }

    .contact-image {
      object-fit: contain;
      width: 50%;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;

      .icon {
        display: flex;
        border-radius: 50%;
      }
    }

    .signup-button {
      text-align: center;
      a {
        &:hover {
          box-shadow: inset -10em 0 0 0 #f97316, inset 10em 0 0 0 #f97316;
          color: #fff;
        }
      }
    }
  }
}
