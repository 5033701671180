.gallery {
  padding: 64px 20px;
  background: #f8f9fa;
}

@media (max-width: 768px) {
  .gallery {
    .slick-arrow {
      width: 30px;
      height: 30px;
      &:before {
        font-size: 30px;
      }
    }
  }
}
