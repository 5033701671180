.subjects {
  padding: 64px 20px 20px 20px;
  text-align: center;

  .card {
    transition: all linear 0.3s;

    img {
      transition: all linear 0.3s;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
        rgba(0, 0, 0, 0.05) 0px 5px 10px;
      transition: all linear 0.3s;

      img {
        transform: rotateY(180deg);
      }

      button {
        color: white;
        background-color: #14398a;

        &:hover {
          color: white;
          border: solid 1px #fb923c;
          box-shadow: inset -10em 0 0 0 #fb923c, inset 10em 0 0 0 #fb923c;
        }
      }
    }
  }

  .slick-slider {
    .slick-slide {
      padding: 20px 20px 40px 20px;
    }
  }
}

@media (max-width: 640px) {
  .subjects {
    padding-bottom: 64px;
    .slick-slider {
      .slick-slide {
        padding: 0;
      }

      .slick-arrow {
        width: 30px;
        height: 30px;
        &:before {
          font-size: 30px;
        }
      }
    }
  }
}
