.carousel {
  background: url("../../../assets/img/background-wave.jpg") no-repeat;
  background-size: cover;
  overflow: hidden;

  .slider {
    height: 100vh;
    display: flex !important;

    .left {
      width: 50%;

      .content {
        position: relative;
        height: 100%;

        .slide-animate {
          position: absolute;
          top: 35%;
          left: 15%;

          h1 {
            font-size: 50px;
            font-weight: 600;
          }

          p {
            padding: 35px 0;
            font-size: 20px;
            font-weight: 500;
          }

          a {
            &:hover {
              box-shadow: inset -10em 0 0 0 #fb923c, inset 10em 0 0 0 #fb923c;
              color: #fff;
            }
          }
        }
      }
    }

    .illustration {
      display: flex;
      width: 50%;
    }
  }

  .slick-slider .slick-dots {
    bottom: 20px;

    li button {
      &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.75;
        color: transparent;
        font-size: 16px;
        // line-height: 14px;
        width: 14px;
        height: 14px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transition: all 0.5s linear;
      }

      &:hover {
        &:before {
          opacity: 1;
          color: #14398a;
          transition: all 0.5s linear;
        }
      }
    }

    .slick-active {
      button {
        &:before {
          color: #14398a;
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .carousel {
    .slider {
      height: 50vh;

      .left {
        .content {
          .slide-animate {
            top: 30%;
            left: 10%;

            h1 {
              font-size: 40px;
              font-weight: 600;
            }

            p {
              padding: 20px 0;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .carousel {
    .slider {
      display: block !important;
      height: 90vh;

      .left {
        width: 100%;
        height: 45%;
        .content {
          position: unset;
          padding-top: 25%;
          padding-left: 10%;

          .slide-animate {
            position: unset;
            h1 {
              font-size: 24px;
              font-weight: 600;
            }

            p {
              padding: 10px 0;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }

      .illustration {
        display: block;
        width: 100%;
      }
    }
  }
}
