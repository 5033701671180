// Breakpoints
$bp-maggie: 15em;
$bp-lisa: 30em;
$bp-bart: 48em;
$bp-marge: 62em;
$bp-desktop: 64em;
$bp-homer: 75em;

.tuition {
  font-size: 16px;

  &.container {
    text-align: left;
    margin-bottom: 20px;
    padding: 100px 3% 5%;

    @media (min-width: $bp-bart) {
      padding: 80px 2% 2%;
    }

    .title {
      font-size: 25px;
      text-align: center;
      margin-bottom: 12px;
      // max-width: 200px;
      // margin: 0 auto 20px;
      // padding: 10px 20px;
      // text-align: center;
      // border-radius: 8px;
      // color: #fff;
      // background-image: linear-gradient(180deg, #1488cc, #2b32b2);
    }
  }
}

.table-container {
  display: grid;
  grid-template-columns: auto;
  gap: 10px 50px;
  margin: 5% 3%;

  @media (min-width: $bp-bart) {
    margin: 2%;
  }

  @media (min-width: $bp-desktop) {
    grid-template-columns: auto auto;
  }
}

.responsive-table {
  border-spacing: 0;

  @media (min-width: $bp-bart) {
    font-size: 0.9em;
  }

  @media (min-width: $bp-marge) {
    font-size: 1em;
  }

  thead {
    // Accessibly hide <thead> on narrow viewports
    font-size: 14px;
    clip: auto;
    padding: 0;
    border: 0;
    height: auto;
    width: auto;
    overflow: auto;

    th {
      background-image: linear-gradient(180deg, #1488cc, #2b32b2);
      border: 1px solid #677fc2;
      font-weight: bold;
      text-align: center;
      color: white;

      &:first-of-type {
        text-align: left;
      }
    }
  }

  // Set these items to display: block for narrow viewports
  tbody,
  tr,
  th,
  td {
    padding: 0;
    text-align: left;
    white-space: normal;
  }

  tr {
    display: table-row;
  }

  th,
  td {
    padding: 0.5em;
    vertical-align: middle;
    display: table-cell;

    @media (min-width: $bp-lisa) {
      padding: 0.75em 0.5em;
    }

    @media (min-width: $bp-bart) {
      padding: 0.5em;
    }

    @media (min-width: $bp-marge) {
      padding: 0.75em 0.5em;
    }

    @media (min-width: $bp-homer) {
      padding: 0.75em;
    }
  }

  caption {
    caption-side: top;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    padding-top: 0;

    @media (min-width: $bp-bart) {
      font-size: 1.5em;
    }
  }

  tfoot {
    font-size: 0.8em;
    font-style: italic;

    @media (min-width: $bp-marge) {
      font-size: 0.9em;
    }
  }

  tbody {
    font-size: 14px;
    display: table-row-group;
    @media (min-width: $bp-bart) {
    }

    tr {
      margin-bottom: 1em;
      display: table-row;
      border-width: 1px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:nth-of-type(even) {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }

    th[scope="row"] {
      color: rgba(0, 0, 0.87);
      text-align: left;
      border: 1px solid #677fc2;
    }

    td {
      border: 1px solid #677fc2;
      text-align: center;
    }

    td[data-type="currency"] {
      text-align: right;
    }

    td[data-title]:before {
      float: left;
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.54);

      @media (min-width: $bp-lisa) {
        font-size: 0.9em;
      }
    }
  }
}
