.signup-students {
  padding: 90px 0;

  .container {
    width: calc(100% - 32px);
    text-align: left;

    .ant-col {
      .float-label {
        margin-top: 0;
      }
      .ant-form-item-explain {
        margin-bottom: 15px;
      }
    }

    .button-form {
      width: 100%;
      background-color: #14398a;
      height: unset;
      font-size: 16px;
      padding: 10px 12px;

      span {
        color: #fff;
      }

      &:hover,
      &:focus {
        border: solid 1px #fb923c;
        transition: all linear 0.3s;
        box-shadow: inset -40rem 0 0 0 #fb923c, inset 40rem 0 0 0 #fb923c;
      }
    }

    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        position: unset;
      }
    }
  }
}

@media (min-width: 768px) {
  .signup-students {
    .container {
      padding: 0 15%;
    }
  }
}
